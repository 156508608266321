import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'normalize.css'
import reportWebVitals from './reportWebVitals';
import BaseRouter from './router/index.jsx';
import { Provider } from 'react-redux'
import store from './store'
import i18n from "i18next";
// import * as Sentry from '@sentry/react';
import { initReactI18next } from "react-i18next";
import en_US from "./locales/en-US.json";
import zh_CN from "./locales/zh-CN.json";
import zh_TW from "./locales/zh-TW.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";
import es from "./locales/es.json";
import { StyleProvider } from '@ant-design/cssinjs';

import { WagmiProvider, createConfig, configureChains, http } from 'wagmi'
// import { } from 'viem'
import { injected } from 'wagmi/connectors'
import { bsc } from 'wagmi/chains';

import '@rainbow-me/rainbowkit/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const config = createConfig({
  // autoConnect: true,
  chains: [
    bsc,
    // bscTestnet
  ],
  // connectors,
  connectors: [
    injected({
      shimDisconnect: true, // 启用断开连接功能
      flags: {
        isTokenPocket: true, // 支持 TokenPocket 检测
        isMetaMask: true
      }
    }),
  ],
  transports: {
    [bsc.id]: http('https://omniscient-little-crater.bsc.quiknode.pro/d1eadd6a609ff5cc671ff6a78d6b2e2983a2082d/'),
  },
})

i18n.use(initReactI18next).init({
  lng:
    localStorage.getItem("lang") == "en_US"
      ? "en_US"
      : (localStorage.getItem("lang") == "zh_CN"
        ? "zh_CN"
        : (localStorage.getItem("lang") == "pl"
          ? "pl"
          : (localStorage.getItem("lang") == "ja" ? "ja" : (localStorage.getItem("lang") == "ko" ? "ko" : (localStorage.getItem("lang") == "es" ? "es" : "zh_TW"))))),
  fallbackLng: "zh_CN",
  debug: true,
  resources: {
    en_US: {
      translation: en_US,
    },
    zh_CN: {
      translation: zh_CN,
    },
    zh_TW: {
      translation: zh_TW,
    },
    ja: {
      translation: ja,
    },
    ko: {
      translation: ko,
    },
    es: {
      translation: es,
    }
  },
});

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiProvider
      config={config}
      reconnectOnMount={true}
    >
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <StyleProvider hashPriority="high">
            <BaseRouter />
          </StyleProvider>
        </Provider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
);

reportWebVitals();
export { store } //导出store